import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, FormRecord, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { delay, take } from 'rxjs/operators';

import { WipColumn, WipWorkspaceSpec } from './wip-form.model';
import { combineLatest, Subscription } from 'rxjs';
import { StateService } from '../../../state.service';
import { Lab } from '@lims-common-ux/lux';
import { SortOption } from './sortable-columns.resolver';

@Component({
  selector: 'app-wip-form',
  templateUrl: './wip-form.component.html',
  styleUrls: ['./wip-form.component.scss'],
})
export class WipFormComponent implements OnInit, OnDestroy {
  labs: Array<Lab> = [];
  currentLab: string;
  workspaces: Array<WipWorkspaceSpec> = [];
  columns: Array<WipColumn> = [];
  sortOptions: Array<SortOption> = [];
  workspacesSelected: Array<WipWorkspaceSpec> = undefined;
  workspacesForm: FormRecord<FormControl<boolean>>;
  assaysSelectorForm: UntypedFormControl;
  workspacesFormInvalid = false;
  assaySelectorFormInvalid = false;
  daysBackValues = [0, 1, 3, 7, 8, 15, 30, 90];
  form = this.newFormGroup();
  queryParams: Params;
  statusChangesSub: Subscription;
  assaysSelectorStatusSub: Subscription;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private stateService: StateService
  ) {}

  @ViewChild('daysBack', { static: false })
  daysBack: ElementRef;

  @HostListener('document:keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    if (event.key === 's' && event.altKey && this.form.valid) {
      event.preventDefault();
      event.stopPropagation();
      this.submitForm();
    }
  }

  ngOnInit(): void {
    combineLatest([this.route.queryParams, this.route.data]).subscribe(([queryParams, data]) => {
      this.stateService.stopLoading();
      this.form = this.newFormGroup();
      if (this.workspaces.length) {
        this.workspaces = [];
      }
      this.labs = data.wipLabs;
      this.currentLab = queryParams.lab;
      this.workspaces = data.workspaces;
      this.columns = data.wipColumns;
      this.sortOptions = data.sortOptions;
      if (this.sortOptions.length) {
        this.form.controls.sortOption.setValue(this.sortOptions[0].option);
      }
      this.assaysSelectorForm = this.form.controls.assaysSelector;
      this.workspacesForm = this.form.controls.workspaces;
      this.workspaces.forEach((workspace) =>
        this.workspacesForm.addControl(workspace.workspaceCode, new FormControl<boolean>(false))
      );
      this.columns.forEach((c) => {
        this.form.controls.hiddenReportColumns.addControl(c.column, new FormControl<boolean>(true));
      });

      this.labs.forEach((lab) => {
        const checked = queryParams.lab === lab.id;
        this.form.controls.labs.addControl(lab.id, new FormControl<boolean>(checked));
      });

      this.workspacesForm.valueChanges.subscribe((values) => {
        this.workspacesSelected = Object.entries(values)
          .filter((value) => value[1] === true)
          .map((value) => this.workspaces.find((workspace) => workspace.workspaceCode === value[0]));
      });

      setTimeout(() => {
        this.daysBack.nativeElement.focus();
      }, 0);
    });

    this.statusChangesSub = this.workspacesForm.statusChanges.subscribe(() => {
      this.workspacesFormInvalid = this.checkIfFormInvalid(this.workspacesForm);
    });

    // Delay(0) used to prevent "expression has changed after it was checked" error (https://blog.angular-university.io/angular-debugging/)
    this.assaysSelectorStatusSub = this.assaysSelectorForm.statusChanges.pipe(delay(0)).subscribe(() => {
      this.assaySelectorFormInvalid = this.checkIfFormInvalid(this.assaysSelectorForm);
    });
  }

  workspacesValidation(workspaces: FormRecord<FormControl<boolean>>) {
    const val = workspaces.getRawValue();
    if (Object.entries(val).length == 0 || Object.entries(val).some((value) => value[1])) {
      return null;
    }
    return { required: true };
  }

  labsValidation(labs: FormRecord<FormControl<boolean>>) {
    const val = labs.getRawValue();
    if (Object.entries(val).length == 0 || Object.entries(val).some((value) => value[1])) {
      return null;
    }
    return { required: true };
  }

  checkIfFormInvalid(control: AbstractControl): boolean {
    return control.invalid && control.dirty && control.touched;
  }

  toggleWorkspace(event: Event, workspace: WipWorkspaceSpec) {
    event.preventDefault();
    event.stopPropagation();
    const currentlySelected =
      this.workspacesSelected && this.workspacesSelected.find((wrk) => wrk === workspace) !== undefined;
    this.workspacesForm.patchValue({ [workspace.workspaceCode]: !currentlySelected });
    this.workspacesForm.controls[workspace.workspaceCode].markAsDirty();
  }

  submitForm() {
    this.route.queryParams.pipe(take(1)).subscribe((queryParams) => {
      const formValue = this.form.getRawValue();
      const workspaces = Object.entries(formValue.workspaces)
        .filter((value) => value[1] === true)
        .map((value) => value[0])
        .join(',');
      const hiddenColumns = Object.entries(formValue.hiddenReportColumns)
        .filter((value) => value[1] === true)
        .map((value) => value[0])
        .join(',');
      const requestedLabs = Object.entries(formValue.labs)
        .filter((value) => value[1] === true)
        .map((value) => value[0])
        .join(',');

      const reportQueryParams: {
        daysBack: number;
        workspaceCodes: string;
        locale: string;
        lab: string;
        requestedLabs: string;
        excludedAssay?: string;
        hiddenReportColumns?: string;
        sortOption?: string;
      } = {
        daysBack: formValue.daysBack,
        workspaceCodes: workspaces,
        locale: queryParams.locale,
        lab: queryParams.lab,
        requestedLabs,
        hiddenReportColumns: hiddenColumns === '' ? null : hiddenColumns, // we don't want an empty parameter to be passed.
        sortOption: formValue.sortOption,
      };
      if (formValue.assaysSelector?.length) {
        const excludedAssay = formValue.assaysSelector.reduce((acc, workspace) => acc.concat(workspace.assays), []);
        reportQueryParams.excludedAssay = excludedAssay.join(',');
      }
      const url = this.router.createUrlTree(['/reports/wip'], { queryParams: reportQueryParams });
      window.open(url.toString(), '_blank');
    });
  }

  resetForm() {
    const hiddenColumns: { [key: string]: boolean } = {};
    this.columns.forEach((c) => (hiddenColumns[c.column] = true));
    this.form.reset({
      daysBack: 3,
      sortOption: this.sortOptions.length && this.sortOptions[0].option,
      labs: { [this.currentLab]: true },
      hiddenReportColumns: hiddenColumns,
    });
    /**
     * This is unfortunate. Because of the current implementation of the assay-selector, resets act a whole bunch of
     * weird. Ideally, we could just reset the form, and all would be good, but because workspaces is an input to the
     * component, and the act of the input changing causes a write, and an onChange to fire, angular gets confused and
     * things go south. I believe the reset never gets a chance to complete in this case, because the act of reset causes
     * an additional onChange event to fire which resets the process.
     *
     * Moving this to anywhere besides AFTER the reset is called, will break the UI until assay-selector is cleaned up.
     */
    this.workspacesSelected = undefined;
  }

  ngOnDestroy() {
    this.statusChangesSub.unsubscribe();
    this.assaysSelectorStatusSub.unsubscribe();
  }

  private newFormGroup() {
    return new FormGroup({
      daysBack: new FormControl(3),
      sortOption: new FormControl(this.sortOptions.length ? this.sortOptions[0].option : null),
      workspaces: new FormRecord<FormControl<boolean>>({}, this.workspacesValidation),
      labs: new FormRecord<FormControl<boolean>>({}, this.labsValidation),
      hiddenReportColumns: new FormRecord<FormControl<boolean>>({}),
      assaysSelector: new UntypedFormControl([]),
    });
  }

  toggleColumn($event: Event, column: WipColumn) {
    $event.preventDefault();
    $event.stopPropagation();
    const currentlySelected = this.form.controls.hiddenReportColumns.controls[column.column].value;
    this.form.patchValue({ hiddenReportColumns: { [column.column]: !currentlySelected } });
    this.form.controls.hiddenReportColumns.controls[column.column].markAsDirty();
  }

  selectAllLabs() {
    const labControls = this.form.controls.labs.getRawValue();
    const checkedLabControls: { [key: string]: boolean } = {};
    Object.keys(labControls).forEach((labName) => {
      checkedLabControls[labName] = true;
    });
    this.form.controls.labs.setValue(checkedLabControls);
    if (this.form.pristine) {
      this.form.markAsDirty();
    }
  }
}
